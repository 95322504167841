.Modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 990;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    animation: modal-animation 5s;
}

.Modal-Area {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 995;
}

.Modal-Content {
    z-index: 999;
}

.Modal-Close-Button {
    position: fixed;
    right: 0;
    top: 0;
    margin: 8px;
    cursor: pointer;
    transition: .15s;
    z-index: 999;
}
.Modal-Close-Button:hover {
    color: lightgray;
}

@keyframes modal-animation {
    0% {visibility: hidden;}
    100% {visibility: visible;}
}