/*@font-face {
    font-family: Lexend;
    src: url(../../../public/Lexend/Lexend-VariableFont_wght.ttf);
}
*/

:root {
    --font: Lexend;

    --color-main: #B2FBA5;
    --color-secondary: #FAE0F7;
    --color-main-gradient: -webkit-radial-gradient(#E6C1E2, #B68BC1, #A37FAB);
    --color-enabled: var(--color-main);
    --color-disabled: rgb(200,200,200);
    --color-invalid: rgb(117, 236, 161);
    --color-neutral-light: lightgray;
    --color-neutral-dark: gray;

    --margin: 4px;
    --padding: 4px;
    --font-size-small: 14px;
    --font-size-normal: 16px;
    --font-size-title: 32px;
    --font-size-title-sub: 20px;
    --color-positive: lightgreen;
    --color-negative: indianred;
    --border-radius: 12px;
    --border-radius-button: 12px;
    --border-size: 1px;
    --border-type: solid;

    --image-auth: url('/public/images/authimgbg2.webp');
    --gradient-secondary: linear-gradient(-45deg, #FEE9DC,#FBC1AB, #FF9EA7,#FED2E0);
    --gradient-main: linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.3) 50%,#0000 66%) var(--color-main);
    --gradient-radial-main: -webkit-radial-gradient(#E6C1E2, #B68BC1, #B68BC1);
}

@keyframes shine {
    0% { background-position: right; }
}
@keyframes gradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }    
}