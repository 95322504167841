/*  VISIBILITY */
.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}

/* WIDTH */
.w-100 { width: 100%; }
.w-90 { width: 90%; }
.w-80 { width: 80%; }
.w-75 { width: 75%; }
.w-65 { width: 65%; }
.w-60 { width: 60%; }
.w-55 { width: 55%; }
.w-50 { min-width: 50%; width: 50%; }
.w-45 { width: 45%; }
.w-40 { width: 40%; }

/* HEIGHT */
.h-100 { height: 100% }

.min-h-100 {
    min-height: 100%;
}

.h-vh-100 {
    min-height: 100vh;
}
.max-h-vh-100 {
    height: 100vh;
    max-height: 100vh;
}

.max-h {
    min-height: max(100%, 100vh) !important;
    height: max(100%, 100vh) !important;
}

/* MARGIN */
:root {
    --m1: calc(1 * var(--margin));
    --m2: calc(2 * var(--margin));
    --m3: calc(3 * var(--margin));
    --m4: calc(4 * var(--margin));
    --m5: calc(5 * var(--margin));
    --m6: calc(6 * var(--margin));
    --m7: calc(7 * var(--margin));
    --m8: calc(8 * var(--margin));
}
.m-0 { margin: 0px; }
.m-1 { margin: var(--m1); }
.m-2 { margin: var(--m2); }
.m-3 { margin: var(--m3); }
.m-4 { margin: var(--m4); }
.m-5 { margin: var(--m5); }
.m-6 { margin: var(--m6); }
.m-7 { margin: var(--m7); }
.m-8 { margin: var(--m8); }
.mt-0 { margin-top: 0px; }
.mt-1 { margin-top: var(--m1); }
.mt-2 { margin-top: var(--m2); }
.mt-3 { margin-top: var(--m3); }
.mt-4 { margin-top: var(--m4); }
.mt-5 { margin-top: var(--m5); }
.mt-6 { margin-top: var(--m6); }
.mt-7 { margin-top: var(--m7); }
.mt-8 { margin-top: var(--m8); }
.mr-0 { margin-right: 0px; }
.mr-1 { margin-right: var(--m1); }
.mr-2 { margin-right: var(--m2); }
.mr-3 { margin-right: var(--m3); }
.mr-4 { margin-right: var(--m4); }
.mr-5 { margin-right: var(--m5); }
.mr-6 { margin-right: var(--m6); }
.mr-7 { margin-right: var(--m7); }
.mr-8 { margin-right: var(--m8); }
.mb-0 { margin-bottom: 0px }
.mb-1 { margin-bottom: var(--m1); }
.mb-2 { margin-bottom: var(--m2); }
.mb-3 { margin-bottom: var(--m3); }
.mb-4 { margin-bottom: var(--m4); }
.mb-5 { margin-bottom: var(--m5); }
.mb-6 { margin-bottom: var(--m6); }
.mb-7 { margin-bottom: var(--m7); }
.mb-8 { margin-bottom: var(--m8); }
.ml-0 { margin-left: 0px; }
.ml-1 { margin-left: var(--m1); }
.ml-2 { margin-left: var(--m2); }
.ml-3 { margin-left: var(--m3); }
.ml-4 { margin-left: var(--m4); }
.ml-5 { margin-left: var(--m5); }
.ml-6 { margin-left: var(--m6); }
.ml-7 { margin-left: var(--m7); }
.ml-8 { margin-left: var(--m8); }

/* PADDING */
:root {
    --p1: calc(1 * var(--padding));
    --p2: calc(2 * var(--padding));
    --p3: calc(3 * var(--padding));
    --p4: calc(4 * var(--padding));
}
.p-0 { padding: 0px; }
.p-1 { padding: var(--p1); }
.p-2 { padding: var(--p2); }
.p-3 { padding: var(--p3); }
.p-4 { padding: var(--p4); }
.pt-0 { padding-top: 0px; }
.pt-1 { padding-top: var(--p1); }
.pt-2 { padding-top: var(--p2); }
.pt-3 { padding-top: var(--p3); }
.pt-4 { padding-top: var(--p4); }
.pr-0 { padding-right: 0px; }
.pr-1 { padding-right: var(--p1); }
.pr-2 { padding-right: var(--p2); }
.pr-3 { padding-right: var(--p3); }
.pr-4 { padding-right: var(--p4); }
.pb-0 { padding-bottom: 0px }
.pb-1 { padding-bottom: var(--p1); }
.pb-2 { padding-bottom: var(--p2); }
.pb-3 { padding-bottom: var(--p3); }
.pb-4 { padding-bottom: var(--p4); }
.pl-0 { padding-left: 0px; }
.pl-1 { padding-left: var(--p1); }
.pl-2 { padding-left: var(--p2); }
.pl-3 { padding-left: var(--p3); }
.pl-4 { padding-left: var(--p4); }

.d-none { display: none;}
.d-bloc {display: block;}

.pointer { cursor: pointer;}

.absolute {
    position: absolute;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.bold {
    font-weight: bold;
}