.Card {
    cursor: pointer;
    width: 300px;
    min-height: 150px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: var(--border-size) var(--color-border) var(--border-type);
    border-radius: var(--border-radius);
}