.Checkbox {
    --size: 100px;
}

.Checkbox input {
    -webkit-appearance: none;
    width: var(--size);
    height: var(--size);

    background-color: var(--color-element);
	border: 3px solid var(--color-border);
	box-shadow: 0px 1px 1px #091E4240, 0px 0px 1px #091E424F;
	padding: 0px;
    border-radius: var(--border-radius);
    transition: .25s;
    margin: 0;
    cursor: pointer;
}

.Checkbox input:active, .Checkbox input:checked:active {
	
}

.Checkbox input:checked {
	background-color: var(--color-element);;
    border: 3px solid var(--color-main);
	color: var(--color-text-1);
}

.Checkbox input:checked:after {
	content: '';
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: transparent;
}

.Checkbox-Label {
    position: relative;
    width: var(--size);
}

.Checkbox-Label label {
    position: absolute;
    font-size: 14px;
    top: -35px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.Checkbox-Icon {
    position: relative;
    top: 0px;
    left: 0px;
    width: 28px;
    top: 20px;
}

.Checkbox-Icon .FA {
    position: absolute;
    top: 0px;
}


