.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.center {
    justify-content: center;
    align-items: center;
}

.center-h {
    justify-content: center;
}

.center-v {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.end {
    justify-content: end;
    align-items: end;
}

.self-align-end {
    align-self: flex-end;
}

.wrap {
    flex-wrap: wrap;
    gap: 24px;
}

.no-wrap {
    flex-wrap: nowrap;
}

.grow-1 {
    flex-grow: 1;
}
.grow-2 {
    flex-grow: 2;
}
.grow-3 {
    flex-grow: 3;
}
.grow-4 {
    flex-grow: 4;
}
.grow-5 {
    flex-grow: 5;
}

.gap-4 {
    gap: 4px;
}
.gap, .gap-8 {
    gap: 8px;
}
.gap-16 {
    gap: 16px;
}
.gap-24 {
    gap: 24px;
}
.gap-32 {
    gap: 32px;
}