.List {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    min-width: 350px;
    width: 90%;
    max-width: 90%;

}

.List > * {
    gap:8px;
}

.List:first-child {
    margin-bottom: 10px;
}