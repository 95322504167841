.blur  {
    backdrop-filter: blur(10px);
}

.header-animation {
    animation: header-animation 3s;
}

.Link {
    color: var(--color-main);
    text-decoration: none;
    font-size: 17px;
    border-bottom: 2px solid var(--color-main);
}

.title {
    font-size: var(--font-size-title);
    font-weight: bold;
}

.big-title {
    font-size: max(5rem, 5vw);
    font-weight: bold;
}

.title-auth {
    font-size: max(5rem, 5vw);
    line-height: 90%;
    font-weight: bold;
    cursor: default;
    /*background: -webkit-radial-gradient(#E6C1E2, #B68BC1, #A37FAB);*/
}
.main-landing {
    padding: 0 !important;
    animation: landing-main-animation 2s 15s;
}
.anim-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: calc(100vh - 50px);
    overflow-y: hidden;
    overflow-x: hidden;
}
.title-landing {
    max-height: 0px;
    visibility: hidden;
    text-align: center;
    font-size: min(8rem, 20vw);
    font-weight: bold;
    cursor: default;
    line-height: 90%;
    animation: landing-title-animation 5s;
}



.title2-landing {
    font-size: min(8rem, 20vw);
    line-height: 90%;
    font-weight: bold;
    cursor: default;
}
.title2-landing-anim {
    animation: landing-title2-animation 15s;
}

.subtitle-landing {
    font-size: 1rem
}
.subtitle-landing-anim {
    animation: landing-subtitle-animation 15s;
}


.subtitle1-landing {
    max-height: 0px;
    visibility: hidden;
    animation: landing-subtitle1-animation 5s;
}
.subtitle2-landing {
    max-height: 0px;
    visibility: hidden;
    animation: landing-subtitle2-animation 7s;
}
.subtitle3-landing {
    max-height: 0px;
    visibility: hidden;
    animation: landing-subtitle3-animation 10s;
}
.subtitle4-landing {
    max-height: 0px;
    visibility: hidden;
    animation: landing-subtitle4-animation 13s;
}

.background-landing {
    background-color: black;
    animation: landing-background-animation 6s ease-in;
}

.shine-animation-text {
    background: var(--gradient-main);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 300% 100%;
    animation: shine 4s infinite;
}

.shine-animation {
    background: var(--gradient-main);
    background-size: 300% 100%;
    animation: shine 2s infinite;
}

.size-16 {
    font-size: 16px;
}

.size-18 {
    font-size: 18px;
}

.title-sub {
    font-size: var(--font-size-title-sub);
}

.bg-main {
    background: var(--color-secondary);
}

.bg-gradient {
    background: var(--gradient-radial-main);
}

.bg-background {
    background: url('/public/images/background.jpg');
    background-size: cover;
}

.bg-filter {
    backdrop-filter: blur(2px);

}