@media screen and (max-width: 1000px) {
    .Auth-Image-Section {
      display: none !important;
    }
}

.SignUp-Form {

}

.Auth-Text {
  font-size: 5.5vw;
  font-weight: bolder;

  background: linear-gradient(-45deg, #FEE9DC,#FBC1AB, #FF9EA7,#FED2E0);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;
}