html {
    margin: 0;
    font-family: var(--font);
}

body {
    margin: 0;
    min-height: 100vh;
    
    background-color: var(--color-background);
    color: var(--color-text-1);
 }

#root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

header {
    position: fixed;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    /*background-color: var(--color-background);*/
}

nav {
   position: fixed;
   bottom: 0;

   display: flex;
   justify-content: center;

   width: 100%;
   height: 65px;
   
   background-color: var(--color-element);
   box-shadow: 0px 3px 5px black;
   border-radius: 20px 20px 0px 0px;
}

nav section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
    margin: 0;

    width: 100%;
    max-width: 500px;
    height: 100%;
}

main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    align-items: center;
    width: 100%;
    max-width: 100%;
    
    flex: 1;

    margin-top: 48px;
    padding: 0px !important;
    padding-right: 0 !important;
    overflow-x: hidden;
}

main section {
    display: flex;
    flex-direction: column;
    margin: 0px 16px;
    width: calc(100% - 32px);
    max-width: 400px;
    align-items: center;

}

footer {
    padding: 16px 0px;
}

div {
    width: auto;
}

img {
    width: 100%;
    height: auto;
}

button {
    background-color: transparent;
    border: none;
    outline: none; 
    padding: 0;
    margin: 0;
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
    cursor: default;
}

p {
    margin: 0;
    display: inline-block;
}

a {
    -webkit-tap-highlight-color: transparent;
}

fieldset {
    border: 0;
}

::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background-color: var(--color-element);
    border-radius: 10px;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-text-1);
    border-radius: 10px;
}