@keyframes header-animation {
    from { visibility: hidden; }
    to { visibility: visible; }
}

@keyframes landing-main-animation {
    0% {background-color: indianred;}
    19% {background-color: indianred;}
    20% {background-color: royalblue;}
    39% {background-color: royalblue;}
    40% {background-color: goldenrod;}
    59% {background-color: goldenrod;}
    60% {background-color: darkolivegreen;}
    79% {background-color: darkolivegreen;}
    80% {background-color: palevioletred;}
    100% {background-color: palevioletred;}
}

@keyframes landing-title-animation {
    0% {font-size: 0rem; visibility: visible; max-height: unset;}
    25% {font-size:  min(8rem, 20vw); }
    40% {font-size:  min(8rem, 20vw); }
    50% {font-size:  min(6rem, 18vw); }
    60% {font-size:  min(10rem, 22vw); }
    70% {font-size:  min(4rem, 16vw); background-color: transparent; }
    72% { background-color: transparent;}
    75% { color: rgb(20, 20, 20); background-color: rgb(20, 20, 20); }
    100% { color: rgb(20, 20, 20); font-size: 1000vw; background-color: rgb(20, 20, 20); max-height: unset; }
}

@keyframes landing-title2-animation {
    0% {font-size: 0rem; visibility: hidden; max-height: unset;}
    85% {font-size: 0rem; visibility: hidden; max-height: 0px;}
    86% {font-size: 0rem; visibility: visible; max-height: unset;}
    100% {font-size:  min(8rem, 20vw);}    
}
@keyframes landing-subtitle-animation {
    0% {visibility: hidden; font-size: 0rem; max-height: 0px;}
    85% {visibility: hidden; font-size: 0rem; max-height: 0px;}
    86% {visibility: visible; font-size: 0rem; max-height: unset;}
    100% {visibility: visible; font-size: 1rem; max-height: unset;}    
}

@keyframes landing-subtitle1-animation {
    0% {font-size: 0rem; visibility: hidden; max-height: 0px; margin: 0 !important;}
    30% {visibility: visible; max-height: unset; margin: unset;}
    35% {font-size: 1rem; visibility: visible; max-height: unset;}
    60% {font-size: 1rem; visibility: visible; max-height: unset;}
    100% {font-size: 0rem; visibility: hidden; max-height: 0px;  margin: 0 !important;}
}

@keyframes landing-subtitle2-animation {
    0% {font-size: 0rem; visibility: hidden;}
    72% {font-size: 0rem; visibility: hidden;}
    73% {font-size: 0rem; visibility: visible; max-height: unset;}
    75% {font-size: 1rem; visibility: visible;}
    95% {font-size: 1rem; visibility: visible;}
    100% {font-size: 0rem; visibility: hidden; max-height: 0px;}
}

@keyframes landing-subtitle3-animation {
    0% {font-size: 0rem; visibility: hidden; max-height: unset;}
    69% {font-size: 0rem; visibility: hidden;}
    70% {font-size: 0rem; visibility: visible;}
    75% {font-size: 1rem; visibility: visible;}
    95% {font-size: 1rem; visibility: visible;}
    100% {font-size: 0rem; visibility: hidden;}
}

@keyframes landing-subtitle4-animation {
    0% {font-size: 0rem; visibility: hidden; max-height: unset;}
    74% {font-size: 0rem; visibility: hidden;}
    75% {font-size: 0rem; visibility: visible;}
    80% {font-size: 1rem; visibility: visible;}
    98% {font-size: 1rem; visibility: visible;}
    100% {font-size: 0rem; visibility: hidden;}
}