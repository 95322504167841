body[data-theme="light"] {
    --color-background: rgb(255,255,255);
    --color-element: rgb(255,255,255);
    --color-border: rgb(210,210,210);
    --color-text-1: rgb(20, 20, 20);
    --color-text-2: rgb(76,76,76);

    --border-input: var(--border-size) var(--border-type) var(--color-border);
    --border-element: var(--border-size) var(--border-type) var(--color-border);
    --color-main: var(--color-text-1);

    --gradient-main: linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.3) 50%,#0000 66%) var(--color-main);
}