.NavLinks {
}

.NavLinks a {
    color: black;
    font-size: larger;
    text-decoration: none;
    transition: .5s;
}

.NavLinks a:hover {
    text-decoration: underline;
}

.NavLinks .current {
    text-decoration: underline;
}