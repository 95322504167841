.Button {
    cursor: pointer;

    display: flex;
    justify-content: center !important;
    align-items: center !important;

    -webkit-tap-highlight-color: transparent;
    border-radius: var(--border-radius-button);
    font-size: 14px;
    transition: .25s;
}

.Button-Border {
    border: var(--border-type) var(--border-size) var(--color-border);
    padding: 0px 12px;
    margin: 2px 2px;
}
.Button-Border:hover {
    padding: 2px 14px;
    margin: 0px;
    animation: unset;
}

.Button-Animation {
    animation: button-animation .55s infinite alternate ease-in-out;
}

@keyframes button-animation {
    from {padding: 0px 12px; font-size: 16px; margin: 2px 2px; border-color: var(--color-border);}
    to {padding: 2px 14px; font-size: 18px; margin: 0px; border-color: var(--color-text-1);}
}

.Primary {
    color: white;
    background-color: var(--color-main);
    letter-spacing: 1px;

    background: var(--gradient-main);
    background-size: 300% 100%;
    animation: shine 2s infinite;
}

.Secondary {
    color: black;
    letter-spacing: 1px;

    background: var(--gradient-secondary);
    background-size: 300% 100%;
    animation: gradient 5s ease infinite;
}

.Primary:hover, .Secondary:hover {
    filter: opacity(75%);
}

.Button-Enabled {
    
}

.Button-Disabled {
    color: rgb(250, 250, 250);
    background: none;
    background-color: var(--color-disabled) !important;
    cursor: not-allowed;
    animation: none;
    border: none;
}
.Button-Disabled:hover {
    filter: none;
}


.xl {
    min-width: 40px;
    min-height: 40px;
    font-size: 16px;
}
.lg {
    
    min-height: 35px;
}
.md {
    min-width: 50px;
    min-height: 35px;
}
.sm {
    min-height: 25px;
    padding: 0px 10px 0px 9px;
}
.xs {
    min-width: 20px;
    min-height: 20px;
}

.Button + .circle {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: var(--color-main);
    width: 40px;
    height: 40px;
    font-size: 13px;
}

.Button .dot {
    position: relative;

}

.Button .dot span {
    position: absolute;
    left: -5px;
    top: 4px;
    
    width: 8px;
    height: 8px;
    background-color: var(--color-main); 
    border-radius: 50%;
}