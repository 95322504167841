.bg-color-main {
    background-color: var(--color-main);
}

.bg-color-main {
    
}

.fg-color-main {
    color: var(--color-main);
}
.fg-color-element {
    color: var(--color-main);
}

.fg-color-1-text {
    color: var(--color-text-1);
}

.fg-color-2-text {
    color: var(--color-text-2);
}

.fg-color-background {
    color: var(--color-background);
}

.c-main {
    color: var(--color-main);
}
.c-element {
    color: var(--color-element);
}
.c-background {
    color: var(--color-background);
}
.c-text {
    color: var(--color-text-1);
}
.c-positive {
    color: var(--color-positive);
}
.c-negative {
    color: var(--color-negative);
}
.c-neutral-dark {
    color: var(--color-neutral-dark);
}
.c-neutral-light {
    color: var(--color-neutral-light);
}