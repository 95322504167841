.Input {
    position: relative;
    width: 100%;
}

.Input .Input-input {
    color: var(--color-text-1);
    font-family: var(--font);
    box-sizing: border-box;

    padding: 16px;
    
    width: 100%;
    /*height: 32px;*/
    outline: none;

    height: 55px;
    vertical-align:middle;

    border-radius: var(--border-radius);
    border: none;
    border: var(--border-input);

    box-shadow: 0px 1px 1px #091E4240, 0px 0px 1px #091E424F;
    background-color: var(--color-element);
    font-size: medium;
   

    transition: .25s;
}

input::placeholder {
    font-weight: normal;
}

.Input .Input-input:not(:placeholder-shown) {
    padding: 24px 16px 8px 16px;
}

.Input .Input-input:not(:focus):invalid {
    border: var(--border-size) var(--border-type) var(--color-invalid);
}

.Input .Input-error {
    visibility: hidden;
    margin: 2px 8px 0px 0px;
    font-size: x-small;
    color: red;
    text-align: right;
    transition: .15s;
}

.Input .Input-icon {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.Input .Input-label {
    color: var(--color-text-2);
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: 16px;
}

input[type="number"] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }